import { Style } from '@capacitor/status-bar'
import { PrefetchPageLinks } from '@remix-run/react'
import { useEffect } from 'react'

import {
  ConsumptionImage,
  EnvironmentImage,
  LemonAccountImage,
} from './components/images'
import MarketingStory from './components/MarketingStory'
import Stories from './components/Stories'

import { colors } from '~/lemonpie3/theme'
import setStatusBarScheme from '~/utils/statusbar.util'

export { ignoreDialogRevalidation as shouldRevalidate } from '~/revalidationRules'

export default () => {
  useEffect(() => {
    return () => {
      setStatusBarScheme({
        style: Style.Dark,
        backgroundColor: colors.background.neutral.primary,
      })
    }
  }, [])
  return (
    <div className="flex grow flex-col">
      <PrefetchPageLinks page="/login" />
      <Stories>
        <MarketingStory
          marketingTextStart="Veja&nbsp;suas contas&nbsp;Lemon"
          marketingTextEnd="quando&nbsp;quiser"
          backgroundImage={
            <LemonAccountImage className="absolute left-0 top-[8%] z-0 h-[58%] w-full" />
          }
          theme="dark"
          className="bg-expressive-strong"
        />
        <MarketingStory
          marketingTextStart="Acompanhe&nbsp;o seu&nbsp;consumo&nbsp;e"
          marketingTextEnd="a&nbsp;sua&nbsp;economia"
          backgroundImage={
            <ConsumptionImage className="absolute left-0 top-[8%] z-0 h-[60%] w-full" />
          }
          theme="light"
          className="bg-main"
        />
        <MarketingStory
          marketingTextStart="Saiba&nbsp;como você&nbsp;ajuda&nbsp;o"
          marketingTextEnd="meio&nbsp;ambiente"
          backgroundImage={
            <EnvironmentImage className="absolute left-0 top-[8%] z-0 h-[61%] w-full" />
          }
          theme="dark"
          className="bg-black-100"
        />
      </Stories>
    </div>
  )
}
